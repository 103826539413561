/** Imported in `index.tsx`. */
@import '~antd/dist/antd.dark.css';
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');

// breakpoints
$xxl: 1400px;
$xl:  1200px;
$lg:  992px;
$md:  768px;
$sm:  576px;

// max widths at each breakpoint
$xxl-max-width: 1320px;
$xl-max-width:  1140px;
$lg-max-width:  960px;
$md-max-width:  720px;
$sm-max-width:  540px;
$default-max-width: 100%;

* {
  font-family: Roboto, sans-serif;
}

p {
  margin-block-start: .25em;
  margin-block-end: .25em;
}

div, p, ul {
  line-height: 1.7;
}

.d-flex { display: flex; }
.d-flex-col {
  display: flex;
  flex-direction: column;
}
.w100p { width: 100%; }
.h100vh { height: 100vh; }
.text-center { text-align: center; }
.justify-content-center { justify-content: center; }
.align-items-center { align-items: center; }
.font-size-1_4em { font-size: 1.4em; }
.text-justify { text-align: justify; }
.container-center { margin: 0 auto; }
.mt-1 { margin-top: .25rem; }
.mt-2 { margin-top: .5rem; }
.mt-3 { margin-top: .75rem; }
.mt-4 { margin-top: 1rem; }
.mt-5 { margin-top: 1.25rem; }  // todo: adjust other ml,mr,... to standard

.ml-3 { margin-left: 1.5rem; }
.mr-3 { margin-right: 1.5rem; }
.mt-6 { margin-top: 3rem; }
.ml-6{ margin-left: 3rem; }
.mb-6 { margin-bottom: 3rem; }
.mr-6 { margin-right: 3rem; }
.mt-8 { margin-top: 4rem; }

ul {
  margin-block-start: 0;
  margin-block-end: 0;
}

@media only screen and (min-width: $xxl) {
  .container {
    max-width: $xxl-max-width;
  }

  .container-lg {
    max-width: $lg-max-width;
  }
}

@media only screen and (min-width: $xl) and (max-width: $xxl - 1px) {
  .container {
    max-width: $xl-max-width;
  }

  .container-lg {
    max-width: $lg-max-width;
  }
}

@media only screen and (min-width: $lg) and (max-width: $xl - 1px) {
  .container {
    max-width: $lg-max-width;
  }

  .container-lg {
    max-width: $lg-max-width;
  }
}

@media only screen and (min-width: $md) and (max-width: $lg - 1px) {
  .container {
    max-width: $md-max-width;
  }

  .container-lg {
    max-width: $md-max-width;
  }
}

@media only screen and (min-width: $sm) and (max-width: $md - 1px) {
  .container {
    max-width: $sm-max-width;
  }

  .container-lg {
    max-width: $sm-max-width;
  }
}

@media only screen and (max-width: $sm - 1px) {
  .container {
    max-width: $default-max-width;
  }

  .container-lg {
    max-width:  $default-max-width;
  }
}